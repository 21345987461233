








import { Vue, Component } from "vue-property-decorator";
import VesselEnquiryExpiredTable from "@/components/vessel/enquiry/VesselEnquiryExpiredTable.vue";

@Component({ components: { VesselEnquiryExpiredTable } })
export default class ExpiringEnquiriesOverview extends Vue {}
